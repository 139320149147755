import React,{useEffect, useState} from "react";
import "./Fitness3Section.css";

function Fitness3Section(props: any) {
  const { data, section } = props;
  const [headingArr, setHeadingArr] = useState([]);
  useEffect(()=>{
    const arr = data.heading1.split(' ');
    setHeadingArr(arr);
  },[])
  return (
    <div className="w-full flex items-center justify-center">
      <div className="lg:ml-12 parent-div flex flex-col md:flex-row items-center justify-center relative">
        <div className={`detail-div bg-white ${section === 1 && 'mobile-detail-div'}`}>
          <h1 className=" font-bold font-bebas detail-heading1">
            {headingArr.length > 0 && headingArr.map((item, index)=>{
              return(
                index === 2 ? <><span style={{color: '#EA1717'}}>{item}</span>{" "}</> : <>{item}{" "}</>
              )
            })}
          </h1>
          <p className="font-poppins font-semibold mt-2 text-left details-desc mb-2">
            {data.details}
          </p>
        </div>
        <div className="img-div">
        <img
          src={data.img}
          className="detail-img object-top object-cover" alt='fitness1' 
        />
        </div>
      </div>
    </div>
  );
}

export default Fitness3Section;
