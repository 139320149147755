import React from "react";
import './ResultsHeader.css';
import { ResultsHeaderAtom } from "../../../recoil/ResultsAtoms/ResultsHeader.ts";
import { useRecoilState } from "recoil";

function ResultsHeader() {
  const [resultsHeaderDetails, setResultsHeaderDetails] =
    useRecoilState(ResultsHeaderAtom);

    const styles = {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.26),rgba(0, 0, 0, 0.26)) , url("${resultsHeaderDetails?.backgroundImage}")`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat, repeat',
    };
    const mobileStyles = {
      
      backgroundPosition: '50% 0px',
    };

    const getStyles = () => {
      const width = window.innerWidth;
  
      if (width < 480) {
        return { ...styles, ...mobileStyles };
      } else {
        return styles ;
      }
    };
  return (
    <div className="w-full header-bg flex items-center justify-center mt-32" style={getStyles()}>
      <h1 className="font-bold px-24 lg: font-bebas text-6xl sm:text-8xl text-white tracking-wider sm:pt-20 slider-h1 slider-heading-service ">
        {resultsHeaderDetails?.sectionHeading}
      </h1> 
    </div>
  );
}

export default ResultsHeader;
