import { CircularProgress } from "@mui/material";
import React,{useState} from "react";
import './Stories.css';
import { StoriesAtom } from "../../../recoil/ResultsAtoms/ResultsStoriesAtom.ts";
import { useRecoilState } from "recoil";

function Stories({storiesData}) {

  const [isLoading, setIsLoading] = useState(true);

  const [allStoriesDetails, setAllStorieshipDetails] =
    useRecoilState(StoriesAtom);

  function handleVideoLoaded() {
    setIsLoading(false);
  }
  function handleLoadStart() {
    setIsLoading(true);
  }

  return (
    <div className="w-full flex flex-col lifeSection items-center justify-center">
      <h1 className="font-bebas sm:hidden stories-mobile-heading">
      {allStoriesDetails?.sectionHeading}
      </h1>
      <div className="w-full sm:w-4/5 flex flex-col sm:flex-row items-center sm:items-start justify-center ">
        <div className="w-full sm:w-1/2 flex justify-center sm: justify-start">
          <video
            src={allStoriesDetails?.sectionVideo}
            autoPlay
            muted
            loop
            className="lifeImg"
            onPlay={() => {
              handleVideoLoaded();
            }}
            onLoadStart={handleLoadStart}
          />
          {isLoading && (
            <div className="lifeImg absolute z-20 flex items-center justify-center">
              <CircularProgress sx={{ color: "red" }} />
            </div>
          )}
        </div>
        <div className="w-full sm:w-1/2 lifeDesc flex flex-col items-center justify-center">
          <h1 className="font-bebas hidden sm:flex stories-heading">
          {allStoriesDetails?.sectionHeading}
          </h1>
          <p className="font-poppins stories-desc text-left">
          {allStoriesDetails?.sectionDescription}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Stories;
