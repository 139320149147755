import React, { useState } from "react";
import Accordian from "../../../components/Accordian/Accordian.tsx";
import { FaqsAtom } from "../../../recoil/HomeAtoms/FaqAtom.ts";
import { useRecoilState } from "recoil";

interface IData {
  Ifeatured: {
    id: number;
    img?: string;
    title: string;
    description: string;
    desc2: string;
  }[];
}

function Faq() {

  const accordianData: IData["Ifeatured"] = [
    {
      id: 0,
      title: "What is Functional Patterns?",
      description: `Functional Patterns is a revolutionary training system created by Naudi Aguilar. It addresses dysfunctions throughout the body by focusing on the foundations of human movement: standing, walking, running, and throwing. `,
      desc2:
        "At South Bay, our specialists use the Functional Patterns training system to decrease pain from movement and posture. Together, we can help reduce your risk of injury, build strength, and regain control of your body.",
    },
    {
      id: 1,
      title: "Who is a good fit for FP?",
      description:
        "We work with people at any level of fitness, age, or body type. Regardless of your background, we can help decrease chronic pain caused by bad posture and movement problems.",
      desc2:
        "Fill out the contact form for a free consultation and see if Functional Patterns is right for you.",
    },
    {
      id: 2,
      title: "How do I get started?",
      description:
        "Step one, get a Biomechanics Assessment. You will meet with a Functional Patterns specialist to analyze your running mechanics, posture, and discuss your goals with training. ",
      desc2:
        "Biomechanics Assessments are required before participating in personal and group training. Fill out the contact form to schedule a free consultation on your first visit. ",
    },
    {
      id: 3,
      title: "Can I build muscle with FP?",
      description:
        "Yes. Unlike other resistance training systems, Functional Patterns believes building muscle comes from healthy movement. Relying on healthy movement also decreases your risk of injury and helps you maintain muscle mass naturally. ",
      desc2: "",
    },
    {
      id: 4,
      title: "Can FP help me lose weight?",
      description:
        "Following the Functional Patterns training system targets many factors that affect your weight. We achieve weight loss through healthy movement, posture, and dietary guidance. View our Success Stories to see the results we've achieved with weight loss.",
      desc2: "",
    },
    {
      id: 5,
      title: "Can FP reduce chronic pain?",
      description:
        "Training with Functional Patterns helps alleviate pain caused by poor movement and posture. We target the root of problems in your body's biomechanics and train to correct them. View our Success Stories for real-life examples of our results in reducing chronic pain. ",
      desc2: "",
    },
  ];

  
  const [faqsDetails, setFaqsDetails] = useRecoilState(FaqsAtom);

  const [openedQa, setOpenedQa] = useState(-1);

  return (
    <div className="flex items-center justify-center flex-col mt-56 mb-7 sm:mb-28">
      <h1 className="font-bold font-bebas home-headings">{faqsDetails?.sectionHeading}</h1>
      <div className="w-full mx-5 md:w-2/3 my-12">
        {faqsDetails.faqs?.map((data) => {
          return (
            <Accordian
              key={data.id}
              id={data.id}
              content={data.answer}
              content2={""}
              title={data.question}
              openedQa={openedQa}
              setOpenedQa={setOpenedQa}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Faq;
