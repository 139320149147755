// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import 'firebase/storage'
import firebase from 'firebase/compat/app';
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD9FJI-s9KqpdKp3l_1-HDE4bDkBEQhgtc",
  authDomain: "fp-south-bay.firebaseapp.com",
  databaseURL: "https://fp-south-bay-default-rtdb.firebaseio.com",
  projectId: "fp-south-bay",
  storageBucket: "fp-south-bay.appspot.com",
  messagingSenderId: "1017474367778",
  appId: "1:1017474367778:web:5ec69c4e3a222b5132e57b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const app = firebase.initializeApp(firebaseConfig)
  export const fire = getDatabase(app);
  const storage = getStorage(app);
  export {storage, fire as default}