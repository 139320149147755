import { images } from "../../../assets/Images.ts";
import React, {useState, useEffect} from 'react';
import "./GetFunctional.css";
import { GetFunctionalAtom } from "../../../recoil/HomeAtoms/GetFunctionalAtom.ts";
import { useRecoilState } from "recoil";

function GetFunctional() {

  const [benefits, setBenefits] = useState([]);
  const [getFunctionalDetails, setGetFunctionalDetails] =
    useRecoilState(GetFunctionalAtom);
 
    const styles = {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.26),rgba(0, 0, 0, 0.26)) , url("${getFunctionalDetails?.backgroundImage}")`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat, repeat',
    };
    const mobileStyles = {
      
      backgroundPosition: '87% 0px',
    };

    const getStyles = () => {
      const width = window.innerWidth;
  
      if (width < 480) {
        return { ...styles, ...mobileStyles };
      } else {
        return styles ;
      }
    };

  useEffect(() => {
    let tempSliders: any = [];
    let tempArr = Object.values(getFunctionalDetails?.benefits);
    for (let i = 0; i < tempArr?.length; i++) {
      if (tempArr[i]) {
        tempSliders.push(tempArr[i]);
      }
    }
    setBenefits(tempSliders);
  }, []);
    
  return (
    <div className="w-full flex flex-col items-center justify-center" style={getStyles()}>
      <h1 className="font-bold font-bebas text-white pt-20 functional-home-headings">
        {getFunctionalDetails?.sectionHeading}
      </h1>
      <h2 className="font-bebas text-white mt-5 px-10 functional-h3 functional-subHeading functional-header">
        {getFunctionalDetails?.sectionSubHeading}
      </h2>
      <div className="w-4/5 my-8 flex items-center justify-center flex-col sm:flex-row sm:flex-wrap">
        {getFunctionalDetails?.benefits.map((item: any, index: number)=>{
          return(
            <div className="w-full sm:w-1/3 h-48 flex flex-col items-center justify-center mt-5 sm:mt-2">
            <img src={item?.image ? item?.image : images.postureIcon} className="h-32" alt='Functional_Icon'/>
            <h2 className="text-white font-medium font-bebas mt-3 functional-h3">
               {item?.heading}
            </h2>
          </div>
          )
        })}
       
        {/* <div className="w-full sm:w-1/3 h-48 flex flex-col items-center justify-center mt-10 sm:mt-2">
          <img src={images.postureIcon} className="h-32" />
          <h2 className="text-white font-medium font-bebas mt-3 functional-h3">
             IMPROVE POSTURE
          </h2>
        </div>
        <div className="w-full sm:w-1/3 h-48 flex flex-col items-center justify-center mt-10 sm:mt-2">
          <img src={images.weightIcon} className="h-32" />
          <h2 className="text-white font-medium font-bebas mt-3 functional-h3">
             LOSE WEIGHT
          </h2>
        </div>
        <div className="w-full sm:w-1/3 h-48 flex flex-col items-center justify-center mt-10 sm:mt-2">
          <img src={images.mobilityIcon} className="h-32" />
          <h2 className="text-white font-medium font-bebas mt-3 functional-h3">
            INCREASE MOBILITY
          </h2>
        </div>
        <div className="w-full sm:w-1/3 h-48 flex flex-col items-center justify-center mt-10 sm:mt-2">
          <img src={images.painIcon} className="h-32" />
          <h2 className="text-white font-medium font-bebas mt-3 functional-h3">
            DECREASE PAIN
          </h2>
        </div>
        <div className="w-full sm:w-1/3 h-48 flex flex-col items-center justify-center mt-10 sm:mt-2">
          <img src={images.symmetryIcon} className="h-32" />
          <h2 className="text-white font-medium font-bebas mt-3 functional-h3">
            BOOST SYMMETRY
          </h2>
        </div> */}
      </div>
    </div>
  );
}

export default GetFunctional;
