import React, { useRef, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Header from "../../components/Header/Header.tsx";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer.tsx";
import { useRecoilState } from "recoil";
import { ref, onValue, update } from "firebase/database";
import fire from "../../firebase/Firebase.ts";
import { FooterAtom } from "../../recoil/FooterRecoilAtoms/FooterAtom.ts";
import { TopbarAtom } from "../../recoil/TopbarAtoms/TopbarRecoilAtom.ts";
import AlertMessage from "../../components/AlertMessage/AlertMessage.tsx";
import ArticlesHeader from "./ArticlesHeader/ArticlesHeader.tsx";
import { ArticlesHeaderAtom } from "../../recoil/ArticlesAtoms/ArticlesHeaderAtom.ts";
import { ArticlesAtom } from "../../recoil/ArticlesAtoms/ArticlesAtom.ts";
import ArticlesSection from "./ArticlesSection/ArticlesSection.tsx";

function Articles() {
  const { scroll } = useParams();
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [articlesHeaderDetails, setArticlesHeaderDetails] = useRecoilState(ArticlesHeaderAtom);
  const [scrolled, setScrolled] = useState(false);
  const [articlesDetails, setArticlesDetails] = useRecoilState(ArticlesAtom);
  const [footerDetails, setFooterDetails] =
      useRecoilState(FooterAtom);
  const [topbarDetails, setTopbarDetails] =
    useRecoilState(TopbarAtom);


  useEffect(() => {
    if (Object.entries(articlesHeaderDetails).length === 0) {
      getHeaderData();
    }else if(articlesDetails.length === 0){
      getArticlesData();
    }else if (Object.entries(footerDetails).length === 0) {
      getFooterData();
    }else if (Object.entries(topbarDetails).length === 0) {
      getTopbarData();
    } 
    else{
      setLoader(false);
    }
  }, [articlesHeaderDetails, articlesDetails, topbarDetails, footerDetails]);

  const getTopbarData = async () => {
    setLoader(true);
    try {
      const dataRef = ref(fire, "topbar");
      onValue(dataRef, (snapshot) => {
        const data = snapshot.val();
        setTopbarDetails(data);
        console.log('topbar data is fetched', data);
      });
    } catch (err) {
      console.log("error in get topbar data is : ", err);
      setAlertMessage("There is problem in fetching topbar data");
      setIsError(true);
      setOpen(true);
      setLoader(false);
    }
  };

  const getFooterData = async () => {
    setLoader(true);
    try {
      const dataRef = ref(fire, "footer");
      onValue(dataRef, (snapshot) => {
        const data = snapshot.val();
        setFooterDetails(data);
        setLoader(false);
      });
    } catch (err) {
      console.log("error in get footer data is : ", err);
      setAlertMessage("There is problem in fetching footer data");
      setIsError(true);
      setOpen(true);
      setLoader(false);
    }
  };

  const getHeaderData = async () => {
    setLoader(true);
    try {
      const dataRef = ref(fire, "blogs/blogsHeader");
      onValue(dataRef, (snapshot) => {
        const data = snapshot.val();
        setArticlesHeaderDetails(data);
        console.log('articles header data is fetched', data);
      });
    } catch (err) {
      console.log("error in get service header data is : ", err);
      setAlertMessage("There is problem in fetching header data");
      setIsError(true);
      setOpen(true);
      setLoader(false);
    }
  };

  const getArticlesData = async () => {
    setLoader(true);
    try {
      const dataRef = ref(fire, "blogs/blogs");
      onValue(dataRef, (snapshot) => {
        const dataObj = snapshot.val();
        const data = Object.values(dataObj);
        console.log('dataArr is : ', data);
        if (data.length > 0) {
          let tempSliders = [];
          for (let i = 0; i < data.length; i++) {
            if (data[i]) {
              tempSliders.push(data[i]);
            }
          }
          setArticlesDetails(tempSliders);
          
        }
        console.log('services data is fetched', data);
        setLoader(false);
      });
    } catch (err) {
      console.log("error in get services data is : ", err);
      setAlertMessage("There is problem in fetching services data");
      setIsError(true);
      setOpen(true);
      setLoader(false);
    }
  };

  return (
    <div>
      {!loader ? (
        <>
          <Header from="ARTICLES" />
          <ArticlesHeader />
          <ArticlesSection />
          <Footer />
        </>
      ) : (
        <div className="bg-yellow loading-div">
          <div className="gray-loading-div">
            {" "}
            <Box className="loading-box">
              <CircularProgress sx={{ color: "red" }} />
            </Box>
          </div>
        </div>
      )}
      {open && (
        <AlertMessage
          open={open}
          setOpen={setOpen}
          alertMessage={alertMessage}
          isError={isError}
          setIsError={setIsError}
          from="Home"
        />
      )}
    </div>
  );
}

export default Articles;
