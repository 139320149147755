import React, { Component } from "react";
import Slider from "react-slick";
import { images } from "../../../assets/Images.ts";
import "./Slider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/system";
import Button from '@mui/material/Button';

let active = 0;

const handleActive = (i) => {
  // console.log('i for active is ', i);
  active = i;
};

export default class ImageSlider extends Component {
  constructor(props) {
    super(props);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
  }

  play() {
    this.slider.slickPlay();
  }

  pause() {
    this.slider.slickPause();
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      appendDots: (dots) => (
        <div
          style={{
            borderRadius: "10px",
            padding: "10px",
            position: "absolute",
            marginBottom: "1%",
            marginLeft: "-2%",
          }}
        >
          <ul style={{ padding: "10px" }}> {dots} </ul>
        </div>
      ),
      customPaging: (i) => (
        <div
          style={{
            width: "10px",
            height: "10px",
            color: "transparent",
            background: `${active === i ? "white" : "gray"}`,
            borderRadius: "5px",
            position: "absolute",
            zIndex: `${i + 1}`,
          }}
        >
          <span
            className="p-2"
            onClick={() => {
              handleActive(i);
            }}
          >
            {i + 1}
          </span>
        </div>
      ),
    };

    const imgList = [1, 2, 3];

    return (
      <div className="overflow-hidden" style={{marginTop: '134px'}}>
        <Slider
          ref={(slider) => (this.slider = slider)}
          {...settings}
          className="slider-parent overflow-hidden"
        >
          {this.props.homeSliderDetails.map((item: any) => {
            return (
              <div className="banner-tab flex" key={item}>
                <img src={item?.image ? item?.image : images.sliderImg} className="desktop-img h-screen w-screen" alt='sliderImg'/>
                <img src={item?.image ? item?.image : images.mobileSliderImg} className="mobile-img w-screen " alt='sliderImg'/>
                <div className="banner-caption flex flex-col items-center justify-center">
                  <h1>
                    <span className="slider-heading text-center font-bebas font-bold slider-h1 custom-shadow flex items-center justify-center">
                      {item?.heading1}
                    </span>
                  </h1>
                  <p className="font-body font-bebas  font-semibold tracking-wider lg:tracking-wider text-center slider-subHeading">
                    {item?.subHeading}
                  </p>
                  <button className='new-slider-btn font-bebas' onClick={()=>{window.open(`${item?.buttonLink}`, "_blank")}}>
                      {item?.buttonText}
                  </button>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}
