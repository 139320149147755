import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { images } from "../../assets/Images.ts";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './ResultsModal.css';

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "90%",
  bgcolor: "white",
  p: 4,
};

const mobileStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "96%",
  height: "80%",
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
};

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} nextArrowResults`}
        style={{ ...style, display: "block", width: '20px', height: '50px'}}
        onClick={onClick}
      >
        {/* <div className='w-5 h-5 -mt-5 bg-red-200 absolute'></div> */}
        <ArrowForwardIosIcon sx={{ color: "black", fontSize: '40px'  }} />
      </div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} prevArrowResults`}
        style={{ ...style, display: "block", color: "gray", width: '50px', height: '50px'}}
        onClick={onClick}
      >
        <ArrowBackIosIcon sx={{ color: "black", fontSize: '40px'}} />
      </div>
    );
  }

export default function ResultsModal(props: any) {
  const [open, setOpen] = React.useState(false);
  const [dataList, setDataList] = useState([
    {
      id: 1,
      img: images.training1,
      heading1: "BIOMECHANICS",
      heading2: "ASSESSMENT",
      description:
        "Get an in-depth analysis of your running mechanics and posture. Discuss your goals with a specialist and develop an action plan.",
    },
    {
      id: 2,
      img: images.training2,
      heading1: "PRIVATE",
      heading2: "TRAINING",
      description:
        "Start training with a Functional Patterns specialist. Follow a personalized action plan and build a strong foundation.",
    },
    {
      id: 3,
      img: images.training3,
      heading1: "ANTI-GRAVITY",
      heading2: "TANK",
      description:
        "Float in an Anti-Gravity Tank filled with Epsom salt for gentle yet powerful decompression. ",
    }
  ]);
  const settings = {
    dots: true,
    navs: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  useEffect(() => {
    handleOpen();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    props.handleCloseResultsdataModal();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className=""
      >
        <Box
          className="w-screen flex h-screen flex-col items-center justify-start outline-none"
          sx={style}
        >
          <img
            src={images.crossBtn}
            className="w-3 h-3 absolute right-0 top-0 mt-5 mr-5 cursor-pointer"
            alt="closeIcon"
            onClick={handleClose}
          />
          <Box className="w-full px-0 sm:px-2 md:px-7 h-full overflow-scroll text-lg bio-details font-semibold">
            <div className="w-full flex flex-col mt-7 md:mt-20 lg:flex-row items-center justify-center">
              <div className="w-full lg:w-2/5 2xl:w-1/2">
                <Slider {...settings} className="slider-results">
                  {props.modalResultData.images.map((item: any) => {
                    return (
                      <div className="w-full h-full" key={item.id}>
                        <div className="banner-tab results-tab flex">
                          <img src={item} className={`sliderImg w-full h-60 md:h-64 ml-3 md:ml-5 lg:ml-0 lg:h-72 xl:h-96 2xl:h-full lg:ml-3 xl:ml-3 2xl:ml-5 relative z-20`} alt='sliderImg' style={{objectFit: 'contain'}}/>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
              <div className="w-full mt-10 lg:mt-0 md:w-3/5 2xl:w-1/2 flex flex-col lg:flex-row items-center justify-center">
                <div className="w-full lg:w-1/2 flex flex-col items-center justify-center">
                  <h3 className="font-bebas modal-heading">Before</h3>
                  <ul className="modal-list">
                    {props.modalResultData.bedore.map((i, index)=>{
                      return(
                        <li key={index}>{i}</li>
                      )
                    })}
                  </ul>
                </div>
                <div className="w-full lg:w-1/2 flex flex-col items-center justify-center">
                  <h3 className="font-bebas modal-heading">After</h3>
                  <ul className="modal-list">
                  {props.modalResultData.after.map((i, index)=>{
                      return(
                        <li key={index}>{i}</li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
