import React, { useState} from "react";
import './RightDetails.css';
import BiodataModal from "../../../components/BiodataModal/BioModal.tsx";
import { CircularProgress } from "@mui/material";

function RightDetails({tacklingData}) {

  const [openBiodata, setOpenBiodata] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  function handleVideoLoaded() {
    setIsLoading(false);
  }
  function handleLoadStart() {
    setIsLoading(true);
  }
  const handleCloseBiodataModal = () => {
    setOpenBiodata(false);
  };

  return (
    <div className="w-full mb-20 flex flex-col tacklingSection items-center justify-center">
      <h1 className="font-bebas stories-heading tackling-heading">
        {tacklingData.heading}
      </h1>
      <div className="w-full sm:px-10 flex flex-col sm:flex-row items-center sm:items-start justify-center  tackling-parent">
        <div className="w-full sm:w-1/2 sm:flex flex-col items-center justify-center ">
          <span className="flex items-center justify-center flex-col">
            <video
              src={tacklingData.url}
              autoPlay
              muted
              loop
              className="UnlockingImg"
              onPlay={() => {
                handleVideoLoaded();
              }}
              onLoadStart={handleLoadStart}
            />
            {isLoading && (
              <div className="UnlockingImg absolute z-20 flex items-center justify-center -mt-2 md:-mt-5">
                <CircularProgress sx={{ color: "red" }} />
              </div>
            )}
            <p
              className="hidden sm:flex underline bio-btn font-poppins"
              onClick={() => {
                setOpenBiodata(true);
              }}
            >
              {tacklingData.btnName}
            </p>
          </span>
        </div>
        <div className="w-full sm:w-1/2 sm:px-5 2xl:px-24 flex flex-col items-start justify-start text-start details-part">
          <h1 className="tackling-subHead font-bebas text-start">
            "{tacklingData.subHeading}"
          </h1>
          <span className="sm:hidden w-full flex items-center justify-center">
            <p
              className=" underline bio-btn font-poppins"
              onClick={() => {
                setOpenBiodata(true);
              }}
            >
              {tacklingData.btnName}
            </p>
          </span>
          {/* <pre className="text-start tackling-desc font-poppins">
            {tacklingData.description}
          </pre> */}
          <div className='text-start tackling-desc font-poppins' dangerouslySetInnerHTML={{ __html: `${tacklingData.description}` }} />
        </div>
      </div>
      {openBiodata && (
        <BiodataModal
          handleCloseBiodataModal={handleCloseBiodataModal}
          biodata={tacklingData.biodata}
        />
      )}
    </div>
  );
}

export default RightDetails;
