import React,{useRef} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './App.css';
import CustomRouter from './routes/Router.tsx';
import { useOnLoadImages } from "./hooks/useOnLoadImages.tsx";
import Box from '@mui/material/Box';

function App() {

  // const wrapperRef = useRef<HTMLDivElement>(null);
  // const imagesLoaded = useOnLoadImages(wrapperRef);

  return (
    <div className="App">
      {/* <p>
        {!imagesLoaded && (
          <div className="loading-div">
            <div className="gray-loading-div">
              <Box className="loading-box">
                <CircularProgress sx={{ color: "red" }} />
              </Box>
            </div>
          </div>
        )}
      </p> */}
      <CustomRouter />
    </div>
  );
}

export default App;
