import React from "react";
import Box from "@mui/material/Box";
import "./Assessment.css";

function Assessment({serviceDetail}) {
  const styles = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.26),rgba(0, 0, 0, 0.26)) , url("${serviceDetail?.image}")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat, repeat',
  };
  const mobileStyles = {
    
    backgroundPosition: '51% 0px',
  };

  const getStyles = () => {
    const width = window.innerWidth;

    if (width < 480) {
      return { ...styles, ...mobileStyles };
    } else {
      return styles ;
    }
  };
  return (
    <div className="w-full assessment-bg flex flex-col items-center assessment-div" style={getStyles()}>
      <h1 className="font-bold font-bebas text-5xl text-white tracking-wider pt-10 sm:pt-7 service-medium-heading2">
        {serviceDetail?.heading}
      </h1>
      <p className="px-10 font-bebas text-white text-2xl mt-5 sm:mt-3 time-heading prac-h3">
        {serviceDetail?.time} <br />
        {serviceDetail?.rate}
      </p>
      <div className="w-full flex items-center justify-center mt-5">
        <div className="w-5/6 flex flex-col sm:flex-row items-center justify-start ">
          <div className="w-full sm:w-2/3 md:w-1/2 px-5 sm:px-0 text-left text-lg sm:text-xl text-white font-poppins font-medium">
            <p className="para-1 font-medium">
              {serviceDetail?.description}
            </p>
          </div>
          <div className="mt-9 sm:mt-0 w-full sm:w-1/3 text-white text-lg sm:text-xl font-poppins text-left pl-10 sm:ml-10 lg:ml-52 ">
            {serviceDetail?.keyPoints?.map((item, index) =>{
              return(
                <li className="para-1 font-medium" key={index}>{item}</li>
              )
            })}
          </div>
        </div>
      </div>
      <button className='new-services-btn font-bebas' onClick={()=>{window.open(`${serviceDetail?.buttonLink}`, "_blank")}}>
          {serviceDetail?.buttonText}
      </button>
    </div>
  );
}

export default Assessment;
