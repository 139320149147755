import React, { useEffect } from "react";
import { HashRouter, Routes, Route, useLocation } from "react-router-dom";
import About from "../pages/About/About.tsx";
import Home from '../pages/Home/Home.tsx';
import Footer from '../components/Footer/Footer.tsx';
import Services from "../pages/Services/Services.tsx";
import ResultsPage from "../pages/Results/Results.tsx";
import Articles from "../pages/Articles/Articles.tsx";
import ArticleDetailsPage from "../pages/Articles/ArticleDetailsPage/ArticleDetailPage.tsx";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function CustomRouter() {
  return (
    <>
      <HashRouter>
        <ScrollToTop />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/services/:scroll" element={<Services />} />
            <Route path="/results" element={<ResultsPage />} />
            <Route path="/articles" element={<Articles />} />
            <Route path="/articleDetailsPage/:articleId" element={<ArticleDetailsPage />} />
        </Routes>
        {/* <Footer /> */}
      </HashRouter>
    </>
  );
}

export default CustomRouter;