import React from "react";
import { Link } from "react-router-dom";
import { images } from "../../../../assets/Images.ts";

function Article({details}) {
  return (
    <Link to={`/articleDetailsPage/${details.id}`} className='w-full sm:w-1/2 lg:w-1/3 p-7 mt-5 2xl:mt-7'><div className="">
      <div className="w-full">
        <img src={details?.image ? details?.image : images?.cameraIcon} alt='blogImage' className="w-full object-contain" />
      </div>
      <div className="w-full pt-3 flex items-center justify-start">
        <p className="uppercase font-bebas 2xl:py-2 bloger-name active-cat">
          {details?.createdBy} | {details?.date}
        </p>
      </div>
      <h1 className="uppercase 2xl:py-2 font-bebas font-bold text-left blog-heading">
        {details?.blogHeading}
      </h1>
      <p className="font-poppins blog-desc text-left 2xl:font-semibold">
        {details?.blogDescription}
      </p>
    </div></Link>
  );
}

export default Article;
