import React, { useRef, useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Header from "../../components/Header/Header.tsx";
import { useOnLoadImages } from "../../hooks/useOnLoadImages.tsx";
import Box from "@mui/material/Box";
import ResultsHeader from "./ResultsHeader/ResultsHeader.tsx";
import Stories from "./Stories/Stories.tsx";
import Results from "../Home/Results/Results.tsx";
import { images } from "../../assets/Images.ts";
import TacklingSection from "./Tackling/Tackling.tsx";
import RightDetails from "./RightDetails/RightDetails.tsx";
import LeftDetails from "./LeftDetails/LeftDetails.tsx";
import { videos } from "../../assets/Videos.ts";
import Footer from "../../components/Footer/Footer.tsx";
import { useRecoilState } from "recoil";
import { TopbarAtom } from "../../recoil/TopbarAtoms/TopbarRecoilAtom.ts";
import { FooterAtom } from "../../recoil/FooterRecoilAtoms/FooterAtom.ts";
import { HomeResultsAtom } from "../../recoil/HomeAtoms/ResultsAtom.ts";
import { ResultsHeaderAtom } from "../../recoil/ResultsAtoms/ResultsHeader.ts";
import { ResultsAtom } from "../../recoil/ResultsAtoms/ResultsAtom.ts";
import { StoriesAtom } from "../../recoil/ResultsAtoms/ResultsStoriesAtom.ts";
import { HomeResultsSliderAtom } from "../../recoil/HomeAtoms/HomeResultsSlidersAtom.ts";
import { ref, onValue, update } from "firebase/database";
import fire from "../../firebase/Firebase.ts";
import AlertMessage from "../../components/AlertMessage/AlertMessage.tsx";
 
function ResultsPage() {
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);
  const [resultsDataArr, setResultsDataArr] = useState<any>([]);
  const [topbarDetails, setTopbarDetails] =
  useRecoilState(TopbarAtom);
  const [footerDetails, setFooterDetails] = useRecoilState(FooterAtom);
  const [homeResultsDetails, setHomeResultsDetails] =
    useRecoilState(HomeResultsAtom); 
    const [homeResultsSliderDetails, setHomeResultsSliderDetails] =
    useRecoilState(HomeResultsSliderAtom);
  const [resultsHeaderDetails, setResultsHeaderDetails] =
    useRecoilState(ResultsHeaderAtom);
  const [allResultsDetails, setAllResultsDetails] = useRecoilState(ResultsAtom);
  const [allStoriesDetails, setAllStorieshipDetails] =
    useRecoilState(StoriesAtom);

    useEffect(() => {
      if (Object.entries(topbarDetails).length === 0) {
        getTopbarData();
      }else if(allResultsDetails.length === 0){
        getResultsData();
      }else if(Object.entries(resultsHeaderDetails).length === 0){
        getResultsHeaderData();
      }else if(Object.entries(allStoriesDetails).length === 0){
        getResultsStoriesData();
      }  else if (Object.entries(homeResultsDetails).length === 0) {
        getHomeResultsData();
      }else if (Object.entries(footerDetails).length === 0) {
        getFooterData();
      }
      else{
        setLoader(false);
      }
    }, [topbarDetails, allResultsDetails, resultsHeaderDetails, allStoriesDetails, footerDetails, homeResultsDetails]);

    useEffect(()=>{
      let tempResultsDataArr: Array<any> = [];
      if(allResultsDetails.length > 0){
        for(let i=0; i < allResultsDetails.length; i++){
          if(allResultsDetails[i]){
            let obj = {
              "heading": allResultsDetails[i]?.heading,
              "subHeading": allResultsDetails[i]?.subHeading,
              "url": allResultsDetails[i]?.fileLink,
              "description": allResultsDetails[i]?.details,
              "btnName": allResultsDetails[i]?.buttonText,
              "biodata": allResultsDetails[i]?.bio,
              "fileType": allResultsDetails[i]?.fileType
            };
            tempResultsDataArr.push(obj);
          }
          
        }
      }
      setResultsDataArr(tempResultsDataArr);
    },[allResultsDetails])

    const getResultsStoriesData = async () => {
      try {
        const dataRef = ref(fire, "results/stories");
        onValue(dataRef, (snapshot) => {
          const data = snapshot.val();
          setAllStorieshipDetails(data);
          console.log('results stories data is fetched', data);
        });
      } catch (err) {
        console.log("error in get results stories data is : ", err);
        setAlertMessage("There is problem in fetching stories data");
        setIsError(true);
        setOpen(true);
      }
    };

    const getResultsHeaderData = async () => {
      try {
        const dataRef = ref(fire, "results/resultsHeader");
        onValue(dataRef, (snapshot) => {
          const data = snapshot.val();
          setResultsHeaderDetails(data);
          console.log('results header data is fetched', data);
        });
      } catch (err) {
        console.log("error in get results header data is : ", err);
        setAlertMessage("There is problem in fetching header data");
        setIsError(true);
        setOpen(true);
      }
    };

    const getResultsData = async () => {
      try {
        const dataRef = ref(fire, "results/results");
        onValue(dataRef, (snapshot) => {
          const data = snapshot.val();
          setAllResultsDetails(data);
          console.log('results data is fetched', data);
        });
      } catch (err) {
        console.log("error in get results data is : ", err);
        setAlertMessage("There is problem in fetching results data");
        setIsError(true);
        setOpen(true);
      }
    };
    
    const getTopbarData = async () => {
      setLoader(true);
      try {
        const dataRef = ref(fire, "topbar");
        onValue(dataRef, (snapshot) => {
          const data = snapshot.val();
          setTopbarDetails(data);
          console.log('topbar data is fetched', data);
        });
      } catch (err) {
        console.log("error in get topbar data is : ", err);
        setAlertMessage("There is problem in fetching topbar data");
        setIsError(true);
        setOpen(true);
        setLoader(false);
      }
    };

    const getFooterData = async () => {
      setLoader(true);
      try {
        const dataRef = ref(fire, "footer");
        onValue(dataRef, (snapshot) => {
          const data = snapshot.val();
          setFooterDetails(data);
          setLoader(false);
        });
      } catch (err) {
        console.log("error in get footer data is : ", err);
        setAlertMessage("There is problem in fetching footer data");
        setIsError(true);
        setOpen(true);
        setLoader(false);
      }
    };

    const getHomeResultsData = async () => {
      try {
        const dataRef = ref(fire, "home/results");
        onValue(dataRef, (snapshot) => {
          const data = snapshot.val();

          let tempHomeResultsArr: any = [];
          let tempAllDataList: any = [];
          for (let i = 0; i < data?.results?.length; i++) {
            if (data?.results[i]) {
              tempHomeResultsArr.push(data?.results[i]);
            }
          }
          for (let i = 0; i < tempHomeResultsArr?.length; i++) {
            let obj = {
              images: tempHomeResultsArr[i].imagesArr,
              heading: tempHomeResultsArr[i].heading,
              bedore: tempHomeResultsArr[i].before,
              after: tempHomeResultsArr[i].after,
            };
            tempAllDataList.push(obj);
          }
          console.log("tempAllDataList is : ", tempAllDataList);
          setHomeResultsSliderDetails(tempAllDataList);
          console.log('home results data is fetched', data);
        });
        setHomeResultsDetails(data);
      } catch (err) {
        console.log("error in home results data is : ", err);
        setLoader(false);
      }
    };

  const storiesData = {
    heading: 'Life changing stories',
    description: 'There are countless stories of people from all backgrounds who’ve improved their posture, athletic performance, and overall quality of life using Functional Patterns. FP South Bay can even deliver results for people who have pain, discomfort, and limited mobility. Here are our stories.',
    url: videos.video5
  }

  return (
    <div ref={wrapperRef}>
      {!loader ? (
      <>
      <Header from="RESULTS" />
      <ResultsHeader />
      <Stories storiesData={storiesData}/>
      <Results from='results' />
      {resultsDataArr.length > 0 && resultsDataArr?.map((item, index) => {
        return(
          <>
          {item.fileType === 'image' ? <TacklingSection tacklingData={item}/> : <> {index % 2 === 0 ? <LeftDetails tacklingData={item} from='' /> :  <RightDetails tacklingData={item}/> }</>}
     </>
      )
      })}
      <Footer />
      </>) : 
        (
        <div className="w-full h-screen flex items-center justify-center">
            <div className="">
              {" "}
              <Box className="about-loading-box">
                <CircularProgress sx={{ color: "red" }} />
              </Box>
            </div>
          </div>
      )}
      {open && (
        <AlertMessage
          open={open}
          setOpen={setOpen}
          alertMessage={alertMessage}
          isError={isError}
          setIsError={setIsError}
          from="Home"
        />
      )}
    </div>
  );
}

export default ResultsPage;
