import Box from "@mui/material/Box";
import React, { useState } from "react";
import { images } from "../../../assets/Images.ts";
import InstagramIcon from "@mui/icons-material/Instagram";
import "./PractionerSection.css";
import BiodataModal from "../../../components/BiodataModal/BioModal.tsx";

function PractionersSection(props: any) {
  const { data } = props;
  const [openBiodata, setOpenBiodata] = useState(false);
  const handleCloseBiodataModal = () => {
    setOpenBiodata(false);
  };

  const goToLink = (openingUrl: string) => {
    window.open(openingUrl, '_blank', 'noopener,noreferrer');
  }

  return (
    <Box
      className="w-5/6 lg:w-3/4 xl:w-4/5 2xl:w-2/3 practioner-section flex flex-col md:flex-row items-center justify-center mb-9"
      sx={{ backgroundColor: "rgba(217, 217, 217, 0.45)" }}
    >
      <div className="w-full practioner-img">
        <img
          src={data.img !== "" ? data.img : images.practionerImgIcon}
          className="w-full practioner-img md:object-cover" alt='practioner'
        />
      </div>
      {/* ----------------------------------------------------desktop screen details-------------------------- */}
      <div className="w-full hidden md:w-2/3 lg:w-3/4 xl:w-4/5 md:px-5 lg:pl-20 lg:pr-24 md:flex flex-col items-start justify-start">
        <div className="w-full flex items-center justify-between md:mt-2">
          <h1 className="font-bebas font-bold prac-h3">{data.heading1}</h1>
          <div className="flex items-center justify-center lg:-mt-4">
            {data.fb !== "" && (
              <img src={images.fbIconGray} className="w-4 h-6 2xl:w-6 2xl:h-9 cursor-pointer" alt='practioner' onClick={()=>{goToLink(data.fb)}}/>
            )}
            {data.insta !== "" && (
              <img src={images.instagramIcon} className="ml-2 w-6 h-6 2xl:w-9 2xl:h-9 cursor-pointer" alt='practioner' onClick={()=>{goToLink(data.insta)}}/>
            )}
          </div>
        </div>
        <h1 className="font-bebas font-bold text-gray-600 mt-1 prac-h3">
          {data.heading2}
        </h1>
        <p className=" lg:mt-2 font-poppins font-semibold text-left para-1-about">
          {data.details}
          <span
            className="text-red-600 cursor-pointer"
            onClick={() => {
              data.heading1 !== "Jose Mendoza" && setOpenBiodata(true);
            }}
          >
            <span className="underline">More</span>
          </span>
        </p>
      </div>
      {/* --------------------------------------------mobile details--------------------- */}
      <div className="w-full flex md:w-2/3 lg:w-3/4 md:h-80 lg:h-66 px-5 md:px-5 lg:px-12 md:hidden flex-col items-center justify-center">
        <div className="w-full flex items-center justify-center mt-5">
          <h1 className="font-bebas font-bold prac-h3">{data.heading1}</h1>
        </div>
        <h1 className="font-bebas font-bold text-gray-600 mt-1 prac-h3">
          {data.heading2}
        </h1>
        <p className=" mt-5 font-poppins font-semibold text-left mb-7 para-1-about">
          {data.details.length < 162 ? (
            data.details
          ) : (
            <>{data.details.substring(0, 162)}...</>
          )}
        </p>
        <Box
          className="selected-btn w-44 text-white shadow-md lg:w-56 pt-2 h-11 flex items-center justify-center text-2xl font-anekGurMukhi tracking-wide rounded-xl md:mt-5 lg:mt-10 cursor-pointer hover:bg-white hover:text-red-600 services-btn font-semi-bold"
          sx={{ background: "#EA1717" }}
          onClick={() => {
            data.heading1 !== "Jose Mendoza" && setOpenBiodata(true);
          }}
        >
          READ BI0
        </Box>
        <div className="flex items-center justify-center my-5">
          {data.fb !== "" && (
            <img src={images.fbIconGray} className="w-3 h-5" alt='fbIcon' onClick={()=>{goToLink(data.fb)}}/>
          )}
          {data.insta !== "" && (
            <InstagramIcon className=" ml-2" sx={{ color: "#535554" }} onClick={()=>{goToLink(data.insta)}}/>
          )}
        </div>
      </div>
      {openBiodata && (
        <BiodataModal
          handleCloseBiodataModal={handleCloseBiodataModal}
          biodata={data.biodata}
        />
      )}
    </Box>
  );
}

export default PractionersSection;
