import React, { useRef, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Header from "../../components/Header/Header.tsx";
import { images } from "../../assets/Images.ts";
import PractionersSection from "./PractionersSection/PractionersSection.tsx";
import { useOnLoadImages } from "../../hooks/useOnLoadImages.tsx";
import Box from "@mui/material/Box";
import "./About.css";
import Fitness3Section from "./FItness1Section/Fitness3Section.tsx";
import Fitness4Section from "./FItness1Section/Fitness4Section.tsx";
import Footer from "../../components/Footer/Footer.tsx";
import { TopbarAtom } from "../../recoil/TopbarAtoms/TopbarRecoilAtom.ts";
import { useRecoilState } from "recoil";
import { FooterAtom } from "../../recoil/FooterRecoilAtoms/FooterAtom.ts";
import { StaticPageAtom } from "../../recoil/AboutAtoms/StaticPageAtom.ts";
import { TrainingAtom } from "../../recoil/AboutAtoms/TrainingAtom.ts";
import { PractionerAtom } from "../../recoil/AboutAtoms/PractionerAtom.ts";
import { ref, onValue, update } from "firebase/database";
import fire from "../../firebase/Firebase.ts";
import AlertMessage from "../../components/AlertMessage/AlertMessage.tsx";

function About() {

  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const aboutRef = useRef<HTMLDivElement>(null);
  const [fitnessDataArr, setFitnessDataArr] = useState<any>([]);
  const [practionersDataArr, setPractionersDataArr] = useState<any>([]);
  const imagesLoaded = useOnLoadImages(aboutRef);
  const [topbarDetails, setTopbarDetails] =
  useRecoilState(TopbarAtom);
  const [footerDetails, setFooterDetails] = useRecoilState(FooterAtom);
  const [allStaticPageDetails, setAllStaticPageDetails] =
    useRecoilState(StaticPageAtom);
  const [allTrainingDetails, setAllTrainingDetails] =
    useRecoilState(TrainingAtom);
  const [allPractionerDetails, setAllPractionerDetails] =
    useRecoilState(PractionerAtom);

    useEffect(() => {
      if (Object.entries(topbarDetails).length === 0) {
        getTopbarData();
      }else if(allPractionerDetails.length === 0){
        getPractionersData();
      }else if(Object.entries(allStaticPageDetails).length === 0){
        getStaticPageData();
      } else if(allTrainingDetails.length === 0){
        getTrainingsData();
      }else if (Object.entries(footerDetails).length === 0) {
        getFooterData();
      }
      else{
        setLoader(false);
      }
    }, [topbarDetails, allPractionerDetails, allStaticPageDetails, allTrainingDetails, footerDetails]);

    useEffect(()=>{
      let tempFtnessDataArr: Array<any> = [];
      if(allTrainingDetails.length > 0){
        for(let i=0; i < allTrainingDetails.length; i++){
          if(allTrainingDetails[i]){
            let obj = {
              "id": allTrainingDetails[i]?.id,
              "heading1": allTrainingDetails[i]?.heading,
              "details": allTrainingDetails[i]?.description,
              "img": allTrainingDetails[i]?.image,
            }
            tempFtnessDataArr.push(obj);
          }
          
        }
      }
      setFitnessDataArr(tempFtnessDataArr);
    },[allTrainingDetails])

    useEffect(()=>{
      let tempPractionersDataArr: Array<any> = [];
      if(allPractionerDetails.length > 0){
        for(let i=0; i < allPractionerDetails.length; i++){
          if(allPractionerDetails[i]){
            let obj = {
              heading1: allPractionerDetails[i]?.name,
              details: allPractionerDetails[i]?.about,
              img: allPractionerDetails[i]?.image,
              heading2: allPractionerDetails[i]?.designation,
              insta: allPractionerDetails[i]?.insta,
              fb: allPractionerDetails[i]?.fb,
              biodata: allPractionerDetails[i]?.bio,
            };
            tempPractionersDataArr.push(obj);
          }
          
        }
      }
      setPractionersDataArr(tempPractionersDataArr);
    },[allPractionerDetails])

  const getTrainingsData = async () => {
    try {
      const dataRef = ref(fire, "about/trainings");
      onValue(dataRef, (snapshot) => {
        const data = snapshot.val();
        setAllTrainingDetails(data);
        console.log('about trainings data is fetched', data);
      });
    } catch (err) {
      console.log("error in get about trainings data is : ", err);
      setAlertMessage("There is problem in fetching training data");
      setIsError(true);
      setOpen(true);
    }
  };

  const getPractionersData = async () => {
    try {
      const dataRef = ref(fire, "about/practioners");
      onValue(dataRef, (snapshot) => {
        const data = snapshot.val();
        setAllPractionerDetails(data);
        console.log('about practioners data is fetched', data);
      });
    } catch (err) {
      console.log("error in get practioners data is : ", err);
      setAlertMessage("There is problem in fetching practitioners data");
      setIsError(true);
      setOpen(true);
    }
  };

  const getStaticPageData = async () => {
    try {
      const dataRef = ref(fire, "about/staticData");
      onValue(dataRef, (snapshot) => {
        const data = snapshot.val();
        setAllStaticPageDetails(data);
        console.log('about static data is fetched', data);
      });
    } catch (err) {
      console.log("error in get about static data is : ", err);
      setAlertMessage("There is problem in fetching header and description data");
      setIsError(true);
      setOpen(true);
    }
  };

  const getTopbarData = async () => {
    setLoader(true);
    try {
      const dataRef = ref(fire, "topbar");
      onValue(dataRef, (snapshot) => {
        const data = snapshot.val();
        setTopbarDetails(data);
        console.log('topbar data is fetched', data);
      });
    } catch (err) {
      console.log("error in get topbar data is : ", err);
      setAlertMessage("There is problem in fetching topbar data");
      setIsError(true);
      setOpen(true);
      setLoader(false);
    }
  };

  const getFooterData = async () => {
    setLoader(true);
    try {
      const dataRef = ref(fire, "footer");
      onValue(dataRef, (snapshot) => {
        const data = snapshot.val();
        setFooterDetails(data);
        setLoader(false);
      });
    } catch (err) {
      console.log("error in get footer data is : ", err);
      setAlertMessage("There is problem in fetching footer data");
      setIsError(true);
      setOpen(true);
      setLoader(false);
    }
  };

  const styles = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.26),rgba(0, 0, 0, 0.26)) , url("${allStaticPageDetails?.backgroundImage}")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat, repeat',
  };
  const mobileStyles = {
    backgroundSize: 'cover',
    backgroundPosition: '50% 0px',
    backgroundRepeat: 'no-repeat, repeat',
  };

  const getStyles = () => {
    const width = window.innerWidth;

    if (width < 480) {
      return { ...styles, ...mobileStyles };
    } else {
      return styles ;
    }
  };

  return (
    <div
      className="w-full flex items-center justify-center flex-col" style={{marginTop: '134px'}}
    >
      {!loader ? ( <>
      <Header from="ABOUT" />
      <div className="w-full flex items-center justify-center about-header-bg" style={getStyles()}>
        <h1 className="font-bold px-24 font-bebas text-6xl sm:text-8xl text-white tracking-wider sm:pt-20 slider-h1 slider-heading-service ">
          {allStaticPageDetails?.sectionHeading}
        </h1>
      </div>
      <div className="w-4/5 md:w-2/3 my-14 lg:px-5 text-left text-xl font-poppins font-semibold leading-10" dangerouslySetInnerHTML={{ __html: `${allStaticPageDetails?.aboutDescription}` }}>
        
      </div>

      {fitnessDataArr.length > 0 && fitnessDataArr.map((item, index) => {
        return(
          index % 2 === 0 ? <Fitness3Section data={item} section={1} /> : <Fitness4Section data={item} />
        )
      })}
      <h1
        className="font-bebas font-bold mt-12 sm:mt-40 md:mt-36 mb-12 md:mb-24 practioner-medium-heading2"
        style={{ lineHeight: "57px", letterSpacing: "0.01em" }}
      >
        {allStaticPageDetails?.practionersHeading}
      </h1>
      <span className="w-full mb-24 flex items-center justify-center flex-col">
        {practionersDataArr.length > 0 && practionersDataArr.map((item, index) => {
          return(
            <PractionersSection data={item} />
          )
        })}
      </span>
      <span className='w-full'>
      <Footer />
      </span>
      </>
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
            <div className="">
              {" "}
              <Box className="about-loading-box">
                <CircularProgress sx={{ color: "red" }} />
              </Box>
            </div>
          </div>
      )}
       {open && (
        <AlertMessage
          open={open}
          setOpen={setOpen}
          alertMessage={alertMessage}
          isError={isError}
          setIsError={setIsError}
          from="Home"
        />
      )}
    </div>
  );
}

export default About;
