import React, { useRef, useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Header from "../../components/Header/Header.tsx";
import Faq from "./Faq/Faq.tsx";
import GetFunctional from "./GetFunctional/GetFunctional.tsx";
import Results from "./Results/Results.tsx";
import ImageSlider from "./Slider/Slider.tsx";
import TrainWithUs from "./TrainWithUs/TrainWIthUs.tsx";
import Box from "@mui/material/Box";
import "./Home.css";
import AlertMessage from "../../components/AlertMessage/AlertMessage.tsx";
import { ref, onValue, update } from "firebase/database";
import fire from "../../firebase/Firebase.ts";
import { useRecoilState } from "recoil";
import { TopbarAtom } from "../../recoil/TopbarAtoms/TopbarRecoilAtom.ts";
import Footer from "../../components/Footer/Footer.tsx";
import { HomeSliderAtom } from "../../recoil/HomeAtoms/HomeSliderAtom.ts";
import { TrainWithUsAtom } from "../../recoil/HomeAtoms/TrainWithUsAtom.ts";
import { GetFunctionalAtom } from "../../recoil/HomeAtoms/GetFunctionalAtom.ts";
import { FaqsAtom } from "../../recoil/HomeAtoms/FaqAtom.ts";
import { FooterAtom } from "../../recoil/FooterRecoilAtoms/FooterAtom.ts";
import { HomeResultsAtom } from "../../recoil/HomeAtoms/ResultsAtom.ts";
import { HomeResultsSliderAtom } from "../../recoil/HomeAtoms/HomeResultsSlidersAtom.ts";

function Home() {
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [topbarDetails, setTopbarDetails] =
    useRecoilState(TopbarAtom);
    const [homeSliderDetails, setHomeSliderDetails] = useRecoilState(HomeSliderAtom);
    const [trainWithUsDetails, setTrainWithUsDetails] = useRecoilState(TrainWithUsAtom);
    const [getFunctionalDetails, setGetFunctionalDetails] =
      useRecoilState(GetFunctionalAtom);
    const [faqsDetails, setFaqsDetails] = useRecoilState(FaqsAtom);
    const [footerDetails, setFooterDetails] = useRecoilState(FooterAtom);
    const [homeResultsDetails, setHomeResultsDetails] =
      useRecoilState(HomeResultsAtom);
    const [homeResultsSliderDetails, setHomeResultsSliderDetails] =
      useRecoilState(HomeResultsSliderAtom);
    useEffect(() => {
      if (Object.entries(topbarDetails).length === 0) {
        getTopbarData();
      }else if(homeSliderDetails.length === 0){
        getSlidersData();
      }else if(Object.entries(trainWithUsDetails).length === 0){
        getTrainWithUsData();
      } else if (Object.entries(getFunctionalDetails).length === 0) {
        getFunctionalData();
      }else if(Object.entries(faqsDetails).length === 0){
        getFaqsData();
      }else if (Object.entries(footerDetails).length === 0) {
        getFooterData();
      }
      else if (Object.entries(homeResultsDetails).length === 0) {
        getHomeResultsData();
      }
      else{
        setLoader(false);
      }
    }, [topbarDetails, homeSliderDetails, trainWithUsDetails, getFunctionalDetails, faqsDetails, footerDetails, homeResultsDetails]);

    const getTopbarData = async () => {
      setLoader(true);
      try {
        const dataRef = ref(fire, "topbar");
        onValue(dataRef, (snapshot) => {
          const data = snapshot.val();
          setTopbarDetails(data);
          console.log('topbar data is fetched', data);
        });
      } catch (err) {
        console.log("error in get topbar data is : ", err);
        setAlertMessage("There is problem in fetching topbar data");
        setIsError(true);
        setOpen(true);
        setLoader(false);
      }
    };

    const getSlidersData = async () => {
      try {
        const dataRef = ref(fire, "home/homeSlider");
        onValue(dataRef, (snapshot) => {
          const data = snapshot.val();
          setHomeSliderDetails(data);
          console.log('home slider is fetched', data);
        });
      } catch (err) {
        console.log("error in get sliders is : ", err);
        setOpen(true);
        setAlertMessage("There is problem in fetching slider data");
        setIsError(true);
        setLoader(false);
      }
    };
    const getTrainWithUsData = async () => {
      setLoader(true);
      try {
        const dataRef = ref(fire, "home/trainWithUs");
        onValue(dataRef, (snapshot) => {
          const data = snapshot.val();
          setTrainWithUsDetails(data);
          console.log('train with us is fetched', data);
        });
      } catch (err) {
        console.log("error in get train with us data is : ", err);
        setAlertMessage("There is problem in fetching train with us data");
        setIsError(true);
        setOpen(true);
        setLoader(false);
      }
    }

    const getFunctionalData = async () => {
      setLoader(true);
      try {
        const dataRef = ref(fire, "home/getFunctional");
        onValue(dataRef, (snapshot) => {
          const data = snapshot.val();
          setGetFunctionalDetails(data);
          console.log('getFunctional is fetched', data);
        });
      } catch (err) {
        console.log("error in get train with us data is : ", err);
        setAlertMessage("There is problem in fetching get functional data");
        setIsError(true);
        setOpen(true);
        setLoader(false);
      }
    };

    const getFaqsData = async () => {
      setLoader(true);
      try {
        const dataRef = ref(fire, "home/faqs");
        onValue(dataRef, (snapshot) => {
          const data = snapshot.val();
          setFaqsDetails(data);
          setLoader(false);
        });
      } catch (err) {
        console.log("error in get faqs data is : ", err);
        setAlertMessage("There is problem in fetching FAQ's data");
        setIsError(true);
        setOpen(true);
        setLoader(false);
      }
    }
    const getFooterData = async () => {
      setLoader(true);
      try {
        const dataRef = ref(fire, "footer");
        onValue(dataRef, (snapshot) => {
          const data = snapshot.val();
          setFooterDetails(data);
          setLoader(false);
        });
      } catch (err) {
        console.log("error in get footer data is : ", err);
        setAlertMessage("There is problem in fetching footer data");
        setIsError(true);
        setOpen(true);
        setLoader(false);
      }
    };

    const getHomeResultsData = async () => {
      try {
        const dataRef = ref(fire, "home/results");
        onValue(dataRef, (snapshot) => {
          const data = snapshot.val();

          let tempHomeResultsArr: any = [];
          let tempAllDataList: any = [];
          for (let i = 0; i < data?.results?.length; i++) {
            if (data?.results[i]) {
              tempHomeResultsArr.push(data?.results[i]);
            }
          }
          for (let i = 0; i < tempHomeResultsArr?.length; i++) {
            let obj = {
              images: tempHomeResultsArr[i].imagesArr,
              heading: tempHomeResultsArr[i].heading,
              bedore: tempHomeResultsArr[i].before,
              after: tempHomeResultsArr[i].after,
            };
            tempAllDataList.push(obj);
          }
          console.log("tempAllDataList is : ", tempAllDataList);
          setHomeResultsSliderDetails(tempAllDataList);
          console.log('home results data is fetched', data);
        });
        setHomeResultsDetails(data);
      } catch (err) {
        console.log("error in home results data is : ", err);
        setLoader(false);
      }
    };

  return ( 
    <div>
      {!loader ? (
        <>
          <Header from="HOME" />
          <ImageSlider homeSliderDetails={homeSliderDetails}/>
          <TrainWithUs />
          <GetFunctional />
          <Results from="home" />
          <Faq />
          <Footer />
        </>
      ) : (
        <div className="bg-yellow loading-div">
          <div className="gray-loading-div">
            {" "}
            <Box className="loading-box">
              <CircularProgress sx={{ color: "red" }} />
            </Box>
          </div>
        </div>
      )}
      {open && (
        <AlertMessage
          open={open}
          setOpen={setOpen}
          alertMessage={alertMessage}
          isError={isError}
          setIsError={setIsError}
          from="Home"
        />
      )}
    </div>
  );
}

export default Home;
