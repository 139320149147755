import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./TrainWIthUs.css";
import { images } from "../../../assets/Images.ts";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { TrainWithUsAtom } from "../../../recoil/HomeAtoms/TrainWithUsAtom.ts";
import { useRecoilState } from "recoil";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} nextArrow`}
      style={{ ...style, display: "block", color: "gray" }}
      onClick={onClick}
    >
      <ArrowForwardIosIcon sx={{ color: "#535554", fontSize: '60px'  }} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} prevArrow`}
      style={{ ...style, display: "block", color: "gray"}}
      onClick={onClick}
    >
      <ArrowBackIosIcon sx={{ color: "#535554", fontSize: '60px' }} />
    </div>
  );
}

function TrainWithUs() {
  const settings = {
    dots: false,
    navs: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const mobileSettings = {
    dots: false,
    navs: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const [active, setActive] = useState(-1);
  const [dataList, setDataList] = useState([
    {
      id: 1,
      img: images.training1,
      heading1: "BIOMECHANICS",
      url: `services/${1}`,
      heading2: "ASSESSMENT",
      description:
        "Get an in-depth analysis of your running mechanics and posture. Discuss your goals with a specialist and develop an action plan.",
    },
    {
      id: 2,
      img: images.training2,
      heading1: "PRIVATE",
      heading2: "TRAINING",
      url: `services/${4}`,
      description:
        "Start training with a Functional Patterns specialist. Follow a personalized action plan and build a strong foundation.",
    },
    {
      id: 3,
      img: images.training3,
      heading1: "ANTI-GRAVITY",
      url: `services/${2}`,
      heading2: "TANK",
      description:
        "Float in an Anti-Gravity Tank filled with Epsom salt for gentle yet powerful decompression.",
    },
    {
      id: 4,
      img: images.training4,
      heading1: "GROUP CLASSES",
      url: `services/${5}`,
      heading2: "",
      description:
        "Join a group class to enhance your standing, walking, running, and throwing mechanics.",
    },
    {
      id: 5,
      img: images.training5,
      heading1: "HEALTH",
      url: `services/${3}`,
      heading2: "CONSULTATION",
      description:
        "Compliment your FP training with a comprehensive approach to optimal health with our Health Consultation.",
    },
    {
      id: 6,
      img: images.training6,
      heading1: "YOUTH",
      url: `services/${6}`,
      heading2: "CLASSES",
      description:
        "Enroll in youth classes for a strong foundation – improve athleticism and learn how to prevent injuries.",
    },
  ]);

  const [mobileDataList, setMobileDataList] = useState([
    {
      id: 1,
      img: images.training1,
      heading1: "BIOMECHANICS",
      url: `services/${1}`,
      heading2: "ASSESSMENT",
      description:
        "Get an in-depth analysis of your running mechanics and posture. Discuss your goals with a specialist and develop an action plan.",
    },
    {
      id: 2,
      img: images.training2,
      heading1: "PERSONAL",
      url: `services/${4}`,
      heading2: "TRAINING",
      description:
        "Start training with a Functional Patterns specialist. Follow a personalized action plan and build a strong foundation.",
    },
    {
      id: 3,
      img: images.training3,
      heading1: "ANTI-GRAVITY",
      url: `services/${2}`,
      heading2: "TANK",
      description:
        "Float in an Anti-Gravity Tank filled with Epsom salt for gentle yet powerful decompression. ",
    },
    {
      id: 4,
      img: images.training4,
      heading1: "GROUP CLASSES",
      url: `services/${5}`,
      heading2: "",
      description:
        "Join a group class to enhance your standing, walking, running, and throwing mechanics.",
    },
    {
      id: 5,
      img: images.training5,
      heading1: "HEALTH",
      url: `services/${3}`,
      heading2: "CONSULTATION",
      description:
        "Compliment your FP training with a comprehensive approach to optimal health with our Health Consultation.",
    },
    {
      id: 6,
      img: images.training6,
      url: `services/${6}`,
      heading1: "YOUTH",
      heading2: "CLASSES",
      description:
        "Enroll in youth classes for a strong foundation – improve athleticism and learn how to prevent injuries.",
    },
  ]);

  const [sliders, setSliders] = useState([]);
  const [lastSlider, setLastSlider] = useState({});
  
  const [trainWithUsDetails, setTrainWithUsDetails] = useRecoilState(TrainWithUsAtom);

  useEffect(() => {
    console.log("start is ", dataList);
    setActive(0);
  }, []);

  useEffect(() => {
    let tempSliders: any = [];
    let tempArr = Object.values(trainWithUsDetails?.sliders);
    for(let i=0; i < tempArr?.length; i++){
      if(tempArr[i]){
        let obj:any = {
          id: tempArr[i].id,
          img: tempArr[i].image,
          heading1: tempArr[i].sliderHeading,
          url: `services/${6}`,
          description: tempArr[i].sliderDetails,
        }
        tempSliders.push(obj);
      }
    }
    console.log('before pop up tempSliders is : ', tempSliders);
     let index = tempSliders.length-1;
     console.log('index is : ', index, ' and last slider is : ', tempSliders[index])
        let obj = {
          id: tempSliders[index].id,
          img: tempSliders[index].img,
          heading1: tempSliders[index].heading1,
          url: `services/${6}`,
          description: tempSliders[index].description,
        }
        setLastSlider(obj);
        tempSliders.pop();
        console.log('last slider is : ', lastSlider)
     
    console.log('train with us sliders array is : ', tempSliders,);
    setSliders(tempSliders);
  },[])

  return (
    <div className=" my-20 flex flex-col items-center justify-center">
      <h1 className="font-bold font-bebas tracking-wide home-headings">
        {trainWithUsDetails?.sectionHeading}
      </h1>
      <h2
        className="font-bebas font-semibold mt-5 sub-heading tracking-wider heading-3"
        style={{}}
      >
        {trainWithUsDetails?.sectionSubHeading}
        {/* Train, restore, and build. */}
      </h2>
      {sliders.length > 0 ? <div className=" w-full sm:w-3/4 md:w-2/3 lg:pr-5 mt-10 ">
        <Slider {...settings} className="slider-main">
          {sliders.length > 0 && sliders.map((item: any, index: number) => {
            return (
              // index + 1 !== sliders.length && (
              <span className="w-1/4 w-full">
                
                  <div className="w-full" key={item.id}>
                    <Link to={`services/${item.heading1}`} className="w-full">
                      <div className="w-full banner-tab train-banner flex">
                        <img
                          src={item?.img ? item?.img : images.training1}
                          className="w-full"
                          alt="trainImg"
                        />
                        <div
                          className="flex flex-col items-center justify-center absolute w-full h-full"
                          onMouseEnter={() => {
                            setActive(item?.id);
                          }}
                          onMouseLeave={() => {
                            setActive(0);
                          }}
                        >
                          {active != item?.id ? (
                            <span
                              className="font-body font-bebas font-semibold text-white train-heading"
                              style={{
                                textShadow:
                                  "0px 2.86831px 2.86831px rgba(0, 0, 0, 0.25)",
                              }}
                            >
                              {item?.heading1} <br />
                              {/* {item?.heading2} */}
                            </span>
                          ) : (
                            <span
                              className="text-white font-poppins font-medium sm:px-2 lg:px-7 train-description"
                              style={{
                                textShadow:
                                  "0px 2.86831px 2.86831px rgba(0, 0, 0, 0.25)",
                              }}
                            >
                              {item?.description}
                            </span>
                          )}
                        </div>
                      </div>
                    </Link>
                  </div>
              </span>
              //  )
            );
          })}
          <div className="flex items-center justify-center">
          <Link to={`/services/${lastSlider?.heading1}`}>
            <div className="banner-tab flex">
              <img src={lastSlider?.img ? lastSlider?.img : images.training1} className="w-100" alt='trainingImg'/>
              <div>
                <span className="font-body font-bebas font-bold tracking-wider text-2xl lg:text-4xl text-white">
                  All Services
                </span>
              </div>
              <div
                    className="flex flex-col items-center justify-center absolute w-full h-full"
                    onMouseEnter={() => {
                      setActive(2);
                    }}
                    onMouseLeave={() => {
                      setActive(0);
                    }}
                  >
                    {active != 2 ? (
                      <span
                        className="font-body font-bebas font-semibold text-white train-heading"
                        style={{
                          textShadow: "0px 2.86831px 2.86831px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        {lastSlider?.heading1}
                      </span>
                    ) : (
                      <span
                        className="text-white font-poppins font-medium sm:px-2 lg:px-7 train-description"
                        style={{
                          textShadow: "0px 2.86831px 2.86831px rgba(0, 0, 0, 0.25)"
                        }}
                      >
                        {lastSlider?.description} 
                      </span>
                    )}
                  </div>

            </div>
            </Link>
          </div>
        </Slider>

        {/* ---------------------------------mobile slider---------------------------------- */}

        {/* <Slider {...mobileSettings} className="slider-mobile"> */}
        <div className="slider-mobile">
          {sliders.map((item: any) => {
            return (
              <div
                className="w-full pl-1.5"
                key={item.id}
                style={{ marginBottom: "23px" }}
              >
                <Link to={item.url}>
                  <div className="banner-tab flex">
                    <div className="ml-3">
                      <img src={item?.img} className="w-full" />
                    </div>
                    <div className="flex flex-col items-center justify-start absolute w-full h-full">
                      <span className="font-body font-bebas font-bold tracking-wider train-mobile-heading text-white">
                        {item?.heading1} <br />
                        {/* {item?.heading2} */}
                      </span>
                      <span className="text-white text-base train-mobile-description pt-3 font-poppins px-5">
                        {item?.description}
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
        {/* </Slider> */}
        <Box className="flex items-center justify-center mt-5">
          <Link to={`/services/${1}`}><Box
            className="selected-btn w-44 lg:w-64 h-11 shadow-lg text-white lg:h-16 flex items-center justify-center text-2xl lg:text-4xl font-bebas tracking-wider font-semibold rounded-xl mt-2 md:mt-5 lg:mt-10 cursor-pointer hover:bg-white hover:text-red-600 services-btn"
            sx={{ background: "#EA1717" }}
          >
           {trainWithUsDetails?.buttonText}
          </Box></Link>
        </Box>
      </div> : 'Loading..........' }
    </div>
  );
}

export default TrainWithUs;
