import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { images } from "../../assets/Images.ts";
import "./BioModal.css";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "90%",
  bgcolor: "white",
  p: 4,
};

const mobileStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "96%",
  height: "80%",
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
};

export default function BiodataModal(props: any) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    handleOpen();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    props.handleCloseBiodataModal();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="hidden md:flex"
      >
        <Box
          className="w-screen hidden md:flex h-screen flex-col items-center justify-start outline-none"
          sx={style}
        >
          <img
            src={images.crossBtn} alt='closeIcon'
            className="w-3 h-3 absolute right-0 top-0 mt-5 mr-5 cursor-pointer"
            onClick={handleClose}
          />
          <Box className="w-4/5 h-full overflow-scroll text-lg bio-details font-semibold">
            <div className="font-medium leading-8 font-poppins" dangerouslySetInnerHTML={{ __html: `${props.biodata}` }}></div>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="md:hidden"
      >
        <Box
          className="w-screen h-full flex-col items-center justify-start outline-none"
          sx={mobileStyle}
        >
          <img
            src={images.crossBtn} alt='closeIcon'
            className="w-5 h-5 absolute right-0 top-0 mt-5 mr-5 cursor-pointer"
            onClick={handleClose}
          />
          <Box className="w-full px-2 h-full overflow-scroll leading-9 my-1 mt-2 text-lg bio-details font-semibold">
            <div className="font-medium leading-8 font-poppins" dangerouslySetInnerHTML={{ __html: `${props.biodata}` }}></div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
