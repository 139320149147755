import logo from "./Images/logo.png";
import sliderImg from "./Images/sliderImg.png";
import mobileSliderImg from "./Images/mobileSliderImg.png";
import training1 from "./Images/training1.png";
import training2 from "./Images/training2.png";
import training3 from "./Images/training3.png";
import training4 from "./Images/training4.png";
import training5 from "./Images/training5.png";
import training6 from "./Images/training6.png";
import mobilityIcon from "./Images/mobilityIcon.png";
import weightIcon from "./Images/weightIcon.png";
import stressIcon from "./Images/stressIcon.png";
import postureIcon from "./Images/postureIcon.png";
import painIcon from "./Images/painIcon.png";
import symmetryIcon from "./Images/symmetryIcon.png";
import getFunctionalBg from "./Images/getFunctionalBG.png";
import result1 from "./Images/result1.jpeg";
import result12 from "./Images/result12.jpeg";
import result13 from "./Images/result13.jpeg";
import result14 from "./Images/result14.jpeg";
import result2 from "./Images/result2.jpeg";
import result22 from "./Images/result22.jpeg";
import result23 from "./Images/result23.jpeg";
import result24 from "./Images/result24.jpeg";
import result3 from "./Images/result3.jpeg";
import result32 from "./Images/result32.jpeg";
import result33 from "./Images/result33.jpeg";
import result34 from "./Images/result34.jpeg";
import fbIcon from "./Images/fbIcon.png";
import twitterIcon from "./Images/twitterIcon.png";
import consultationBg from "./Images/consultationBg.png";
import menuIcon from "./Images/menuIcon.png";
import aboutHeader from "./Images/aboutHeader.png";
import mobileAboutHeader from "./Images/mobileAboutHeader.png";
import mobileAbout1 from "./Images/mobileAbout1.png";
import mobileAbout2 from "./Images/mobileAbout2.png";
import mobileAbout3 from "./Images/mobileAbout3.png";
import practionerImg from "./Images/practionerImg.png";
import fbIconGray from "./Images/fbIconGray.png";
import practionerImg2 from "./Images/practionerImg2.png";
import practionerImg3 from "./Images/practionerImg3.png";
import practionerImgIcon from "./Images/practionerImgIcon.png";
import crossBtn from "./Images/crossBtn.png";
import servicesMembership from "./Images/servicesMembership.png";
import mobileLogo from "./Images/mobileLogo.png";
import result4 from "./Images/result4.jpeg";
import result42 from "./Images/result42.jpeg";
import result43 from "./Images/result43.jpeg";
import result44 from "./Images/result44.jpeg";
import result5 from "./Images/result5.jpeg";
import result52 from "./Images/result52.jpeg";
import result53 from "./Images/result53.jpeg";
import result54 from "./Images/result54.jpeg";
import result6 from "./Images/result6.jpeg";
import result62 from "./Images/result62.jpeg";
import result63 from "./Images/result63.jpeg";
import result64 from "./Images/result64.jpeg";
import result7 from "./Images/result7.jpeg";
import result72 from "./Images/result72.jpeg";
import result73 from "./Images/result73.jpeg";
import result74 from "./Images/result74.jpeg";
import result8 from "./Images/result8.jpg";
import result82 from "./Images/result82.jpg";
import result83 from "./Images/result83.jpg";
import result84 from "./Images/result84.jpg";
import instagramIcon from "./Images/instagramIcon.png";
import compressionImg from "./Images/compressionImg.png";
import resultsHeader from "./Images/resultsHeader.png";
import resultsHeaderMobile from "./Images/resultsHeaderMobile.png";
import lifeChanging from "./Images/lifeChanging.png";
import lifeChangingMobile from "./Images/lifeChangingMobile.png";
import tacklingImg from "./Images/tacklingImg.png";
import unlockingImg from "./Images/unlockingImg.png";
import daniel1Img from "./Images/daniel1.png";
import karen1Img from "./Images/karen1.png";
import karen2Img from "./Images/karen2.png";
import blogImg from "./Images/blogImg.png";
import cameraIcon from "./Images/camera-icon.svg";

export const images={
    logo,
    daniel1Img,
    karen1Img,
    karen2Img,
    sliderImg,
    mobileSliderImg,
    training1,
    training2,
    training3,
    mobilityIcon,
    painIcon,
    weightIcon,
    stressIcon,
    postureIcon,
    symmetryIcon,
    getFunctionalBg,
    result1,
    result12,
    result13,
    result14,
    result2,
    result22,
    result23,
    result24,
    result3,
    fbIcon,
    twitterIcon,
    consultationBg,
    menuIcon,
    aboutHeader,
    mobileAboutHeader,
    mobileAbout1,
    mobileAbout2,
    mobileAbout3,
    practionerImg,
    fbIconGray,
    practionerImg2,
    practionerImg3,
    practionerImgIcon,
    crossBtn,
    servicesMembership,
    mobileLogo,
    result4,
    result42,
    result43,
    result44,
    result5,
    result52,
    result53,
    result54,
    result6,
    result62,
    result63,
    result64,
    result7,
    result72,
    result73,
    result74,
    instagramIcon,
    lifeChanging,
    compressionImg,
    resultsHeader,
    resultsHeaderMobile,
    lifeChangingMobile,
    tacklingImg,
    unlockingImg,
    training4,
    training5,
    training6,
    result32,
    result33,
    result34,
    result8,
    result82,
    result83,
    result84,
    blogImg,
    cameraIcon

}