import React from "react";
import Box from "@mui/material/Box";
import "./Membership.css";
import { useRecoilState } from "recoil";
import { MembershipAtom } from "../../../recoil/ServicesAtoms/MembershipAtom.ts";

function Membership() {
  
  const [membershipDetails, setMembershipDetails] = useRecoilState(MembershipAtom);
  const styles = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.26),rgba(0, 0, 0, 0.26)) , url("${membershipDetails?.backgroundImage}")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat, repeat',
  };
  const mobileStyles = {
    
    backgroundPosition: '50% 0px',
  };
  const getStyles = () => {
    const width = window.innerWidth;

    if (width < 480) {
      return { ...styles, ...mobileStyles };
    } else {
      return styles ;
    }
  };

  return (
    <div className="w-full membership-bg flex flex-col items-center membership-div assessment-div" style={getStyles()}>
      <h1 className="font-bold font-bebas text-white pt-10 practioner-medium-heading2">
        {membershipDetails?.sectionHeading}
      </h1>
      {/* <div dangerouslySetInnerHTML={{ __html: `${membershipDetails?.membershipDetails}` }} /> */}
      <p className="px-10 lg:px-40 xl:px-64 2xl:px-96 font-poppins text-white text-xl mt-5 2xl:mt-10 text-left para-1">
        {/* Sign up for a membership and get discounts on all of our services.
        Choose a <br /> package that fits your goals, and contact us to get
        started.{" "} */}
        {membershipDetails?.sectionDescription}
      </p>
      <div className="w-full flex items-center justify-center mt-5">
        <div className="w-5/6 lg:w-4/6 xl:w-1/2 flex items-center justify-center membership-details">
          <div className="w-1/4 h-full ">
            <div className="h-20 lg:h-14 w-full"></div>
            <div
              className="h-20 lg:h-14 w-full text-white font-bebas flex items-center justify-center membership-sessions"
            >
              {membershipDetails?.firstSessionName}
            </div>
            <div
              className="h-20 lg:h-14 w-full text-white font-bebas flex items-center justify-center membership-sessions"
            >
            {membershipDetails?.secondSessionName}
            </div>
          </div>
          <div className="w-1/4 h-full">
            <div
              className=" px-5 h-20 lg:h-14 w-full text-white font-bebas flex items-center justify-center membership-sessions"
            >
              {membershipDetails?.firstDuration}
            </div>
            <div className="h-20 lg:h-14 w-full text-lg font-semibold sm:text-2xl text-white font-poppins flex items-center justify-center border border-2 border-white">
            {membershipDetails?.firstSessionOff1}
            </div>
            <div className="h-20 lg:h-14 w-full text-lg font-semibold sm:text-2xl text-white font-poppins flex items-center justify-center border-l-2 border-r-2 border-b-2 border-white">
            {membershipDetails?.secondSessionOff1}
            </div>
          </div>
          <div className="w-1/4 h-full">
            <div
              className="px-5 h-20 lg:h-14 w-full text-lg sm:text-2xl text-white font-bebas flex items-center justify-center membership-sessions"
            >
              {membershipDetails?.secondDuration}
            </div>
            <div className="h-20 lg:h-14 w-full text-lg font-semibold sm:text-2xl text-white font-poppins flex items-center justify-center border-t-2 border-r-2 border-b-2 border-white">
            {membershipDetails?.firstSessionOff2}
            </div>
            <div className="h-20 lg:h-14 w-full text-lg font-semibold sm:text-2xl text-white font-poppins flex items-center justify-center border-r-2 border-b-2 border-white">
            {membershipDetails?.secondSessionOff2}
            </div>
          </div>
          <div className="w-1/4 h-full">
            <div
              className="px-5 h-20 lg:h-14 w-full text-lg sm:text-2xl text-white font-bebas flex items-center justify-center font-medium membership-sessions"
            >
              {membershipDetails?.thirdDuration}
            </div>
            <div className="h-20 lg:h-14 w-full text-lg font-semibold sm:text-2xl text-white font-poppins flex items-center justify-center border-t-2 border-r-2 border-b-2 border-white">
            {membershipDetails?.firstSessionOff3}
            </div>
            <div className="h-20 lg:h-14 w-full text-lg font-semibold sm:text-2xl text-white font-poppins flex items-center justify-center border-r-2 border-b-2 border-white">
            {membershipDetails?.secondSessionOff3}
            </div>
          </div>
        </div>
      </div>
      <p className="px-10 font-poppins text-white text-lg xl:text-xl mt-9 2xl:mt-12 text-left font-semibold">
      {membershipDetails?.discountDescription}{" "}
      </p>
      <button className='new-services-btn font-bebas' onClick={()=>{window.open(`${membershipDetails?.buttonLink}`, "_blank")}}>
      {membershipDetails?.buttonText}
      </button>
    </div>
  );
}

export default Membership;
